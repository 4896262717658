import './WhyChooseContextCardGroup.css';
import data from "./WhyChooseContextCardGroup.data";


const WhyChooseContextCardGroup = ({mountID}) => {

  // renders
  const render = () => {
    let topElement = document.createElement('div');
    topElement.classList.add('why-choose-context-gp', 'card-gp')

    for (const item of data) {
      let html = `
        <div class="why-choose-context-item" id="why-choose-context-item-${item.id}">

          <div>

            <div class="why-choose-context-card-heading">
              <span class="svg-ct">${item.svg}</span>

              <h3>${item.title}</h3>
            </div>

            <div>
              <p>${item.detail}</p>
            </div>
          </div>

        </div>
      `;
      topElement.innerHTML += html;
    }

    document.getElementById(mountID).append(topElement);
  }

  render();
}


export default WhyChooseContextCardGroup;
