// This file uses js meant only for the home page

import './index.css';
import './hero.css';
import './services.css';
import './comes-with.css';

import WhyChooseContextCardGroup from '../../components/card-groups/WhyChooseContextCardGroup/WhyChooseContextCardGroup';
import ContextOffersMoreCardGroup from '../../components/card-groups/ContextOffersMoreCardGroup/ContextOffersMoreCardGroup';
import UsersNeedContextCardGroup from '../../components/card-groups/UsersNeedContextCardGroup/UsersNeedContextCardGroup';
import CTA1 from '../../components/calls-to-action/cta1/CTA1';

UsersNeedContextCardGroup({mountID: 'users-need-context-gp-mount'});

WhyChooseContextCardGroup({mountID: 'why-choose-context-gp-mount'});

ContextOffersMoreCardGroup({mountID: 'context-offers-more-gp-mount'});


CTA1(
  {
    mountID: 'cta-mount1',
    title: 'Contact Now',
    subtitle: "Want to get the ball rolling faster? Give me a call now!",
    classList: `cta-bkg1`
  }
);


CTA1(
  {
    mountID: 'cta-mount2',
    title: 'Tell Me Your Idea',
    subtitle: "Your idea doesn't need to be complete to get started.",
    detail: "Call me, and let's build off of what you have so far.",
    classList: `cta-bkg1`
  }
);


CTA1(
  {
    mountID: 'cta-mount3',
    title: 'Need More Context?',
    subtitle: "Call while your questions are fresh.",
    classList: `cta-bkg1`
  }
);


const handleReappendFlyIns = () => {
  window.location.href = '#comes-with';
  const comesWithGp = document.getElementById('comes-with-gp');
  const els = document.getElementsByClassName('comes-with-item');
  const newEls = [...els];

  while (els.length) {
    for (const el of els) {
      el.remove();
    }
  }
  for (const el of newEls) {
    comesWithGp.append(el);
  }
}



const readyDOM = () => {
  document.querySelector('.comes-with .fly-btn button').addEventListener('click', handleReappendFlyIns)
}

readyDOM();
