import './CTA1.css'
import { phone, email } from '../../../modules/commonData';


const CTA1 = ({mountID, title, subtitle, detail, classList}) => {

  const render = () => {

    let html = `
    <div class="section-ct cta-mount ${classList}">
        <div class="section-heading-ct">
            <h2>${title}</h2>
        </div>

        ${subtitle ? `<div><h3>${subtitle}</h3></div>` : ''}

        <div class="phone-number-ct">
          <a class="phone-number-link" href="tel:${phone}">
            <span title="Click To Call">${phone}</span>
          </a>
        </div>

        <div class="phone-number-ct">
          <a class="phone-number-link" href="mailto:context.coding@gmail.com">
            <span title="Click To Email">${email}</span>
          </a>
        </div>


        ${detail ? `<div><p>${detail}</p></div>` : ''}

    </div>
    `;

    document.getElementById(mountID).insertAdjacentHTML( 'beforeend', html );
  }

  render();
}


export default CTA1;
