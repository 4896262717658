// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-mount {
  background: linear-gradient(var(--brand-white6), var(--brand-dark5));
  color: var(--brand-white2);
}

.cta-mount h2 {
  text-shadow: var(--text-shadow2);
  color: var(--brand-white2);
}

.cta-mount h3 {
  text-align: center;
}

.cta-mount p {
  text-align: center;
}

.cta-mount a {
  color: var(--brand-green1);
}
.cta-mount a:hover {
  color: var(--brand-white1);
}
`, "",{"version":3,"sources":["webpack://./src/components/calls-to-action/cta1/CTA1.css"],"names":[],"mappings":"AAAA;EACE,oEAAoE;EACpE,0BAA0B;AAC5B;;AAEA;EACE,gCAAgC;EAChC,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".cta-mount {\n  background: linear-gradient(var(--brand-white6), var(--brand-dark5));\n  color: var(--brand-white2);\n}\n\n.cta-mount h2 {\n  text-shadow: var(--text-shadow2);\n  color: var(--brand-white2);\n}\n\n.cta-mount h3 {\n  text-align: center;\n}\n\n.cta-mount p {\n  text-align: center;\n}\n\n.cta-mount a {\n  color: var(--brand-green1);\n}\n.cta-mount a:hover {\n  color: var(--brand-white1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
