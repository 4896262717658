// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-choose-context-item {
  display: flex;
  border: 1px solid black;
  border-radius: 10px;
  border-top: 1px solid var(--brand-white3);
  border-left: 1px solid var(--brand-white3);
  box-shadow: 3px 3px 3px var(--brand-white3);
  background-color: var(--brand-white1);
  padding: 10px;
  width: 100%;
  max-width: 400px;
}

.why-choose-context-card-heading {
  display: flex;
  align-items: center;
}

.why-choose-context-card-heading h3 {
  /* flex-grow: 1; */
  text-align: center;
  padding-left: 10px;
  margin: 0;
  font-size: 150%;
  font-family: var(--font-family1);
}

.why-choose-context-item p {
  text-indent: 1.8rem;
}

.why-choose-context-card-heading .svg-ct {
  width: 50px;
  height: 50px;
}

.why-choose-context-item svg {
  width: 50px;
  height: 50px;
  fill: var(--brand-blue1);
  stroke-width: 10;
  stroke: red;
}`, "",{"version":3,"sources":["webpack://./src/components/card-groups/WhyChooseContextCardGroup/WhyChooseContextCardGroup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,0CAA0C;EAC1C,2CAA2C;EAC3C,qCAAqC;EACrC,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".why-choose-context-item {\n  display: flex;\n  border: 1px solid black;\n  border-radius: 10px;\n  border-top: 1px solid var(--brand-white3);\n  border-left: 1px solid var(--brand-white3);\n  box-shadow: 3px 3px 3px var(--brand-white3);\n  background-color: var(--brand-white1);\n  padding: 10px;\n  width: 100%;\n  max-width: 400px;\n}\n\n.why-choose-context-card-heading {\n  display: flex;\n  align-items: center;\n}\n\n.why-choose-context-card-heading h3 {\n  /* flex-grow: 1; */\n  text-align: center;\n  padding-left: 10px;\n  margin: 0;\n  font-size: 150%;\n  font-family: var(--font-family1);\n}\n\n.why-choose-context-item p {\n  text-indent: 1.8rem;\n}\n\n.why-choose-context-card-heading .svg-ct {\n  width: 50px;\n  height: 50px;\n}\n\n.why-choose-context-item svg {\n  width: 50px;\n  height: 50px;\n  fill: var(--brand-blue1);\n  stroke-width: 10;\n  stroke: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
