import data from "./ContextOffersMoreCardGroup.data";
import './ContextOffersMoreCardGroup.css';


const ContextOffersMoreCardGroup = ({mountID}) => {

  const render = () => {
    let topElement = document.createElement('div');
    topElement.classList.add('context-offers-more-gp', 'card-gp')

    for (const item of data) {
      let html = `
        <div class="context-offers-more-item" >

          <div class="svg-ct"> 
            ${item.svg}

            <h3>${item.title}</h3>

            <p>${item.detail}</p>
          </div> <!-- END svg-ct -->

        </div>
      `;
      topElement.innerHTML += html;
    }

    document.getElementById(mountID).append(topElement)
  }

  render();
}


export default ContextOffersMoreCardGroup;
