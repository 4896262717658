import data from "./UsersNeedContextCardGroup.data";
import './UsersNeedContextCardGroup.css';


const UsersNeedContextCardGroup = ({mountID}) => {

  const render = () => {
    let topElement = document.createElement('div');
    topElement.classList.add('users-need-context-gp');

    for (const item of data) {
      let html = `
      <div class="users-need-context-item-ct" id="users-need-context-item-ct-${item.id}">

        ${item.svg}

        <div class="question-title-detail-ct">
          <h3>${item.title}</h3>
          <p>${item.detail}</p>
        </div>
      </div>
      `;
      topElement.innerHTML += html;
    }

    document.getElementById(mountID).append(topElement)
  }

  render();
}


export default UsersNeedContextCardGroup;
